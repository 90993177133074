<template>
  <div>

    <!-- Authors Table -->
    <a-row :gutter="24" type="flex">

      <!-- Authors Table Column -->
      <a-col :span="24" class="mb-24">

        <a-card :bordered="true" class="header-solid h-full" :bodyStyle="{padding: 0,}">
          <template #title>
            <a-row type="flex" align="middle">
              <a-col :span="24" :md="12">
                <h5 class="font-semibold m-0">
                  <a-tooltip placement="top">
                    <template slot="title">
                      <span>Reset All</span>
                    </template>
                    <a-button type="link" @click="resetTable" class="icon-btn" style="color: black">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none"
                           stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                           class="feather feather-refresh-cw">
                        <polyline points="23 4 23 10 17 10"></polyline>
                        <polyline points="1 20 1 14 7 14"></polyline>
                        <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                      </svg>
                    </a-button>
                  </a-tooltip>
                  Reports
                </h5>
              </a-col>
            </a-row>
          </template>
          <a-table :columns="columns"
                   :row-key="record => record._id"
                   :data-source="data"
                   :pagination="pagination"
                   :loading="loading"
                   @change="handleTableChange">
            <div
                slot="filterDropdown"
                slot-scope="{
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        column
      }"
                class="custom-filter-dropdown"
            >
              <a-input
                  v-ant-ref="c => (searchInput = c)"
                  :placeholder="`Search ${column.dataIndex}`"
                  :value="selectedKeys[0]"
                  @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
                  @pressEnter="() => handleSearch(selectedKeys, confirm)"
                  style="width: 188px; margin-bottom: 8px; display: block;"
              />
              <a-button
                  type="primary"
                  @click="() => handleSearch(selectedKeys, confirm)"
                  icon="search"
                  size="small"
                  style="width: 90px; margin-right: 8px"
              >Search
              </a-button
              >
              <a-button
                  @click="() => handleReset(clearFilters)"
                  size="small"
                  style="width: 90px"
              >Reset
              </a-button
              >
            </div>
            <a-icon
                slot="filterIcon"
                slot-scope="filtered"
                type="search"
                :style="{ color: filtered ? '#108ee9' : undefined }"
            />
            <template slot="sno" slot-scope="text, record, index">
                            {{ ((pagination.current?pagination.current:1)-1)*(pagination.pageSize?pagination.pageSize:15)+(index + 1) }}.
            </template>
            <template slot="name" slot-scope="reason,row">
              <div class="table-avatar-info" style="align-items: center">
                <div class="avatar-info">
                  <h6>{{ reason.title }}</h6>
<!--                  <p style="font-size: 11px">{{-->
<!--                      row.description ? (row.description.substring(0, 40) + (row.description.length > 40 ? '..' : '')) : ''-->
<!--                    }}</p>-->
                </div>
              </div>
            </template>
            <template slot="listings" slot-scope="createdAt,row">
              <div class="table-avatar-info" style="align-items: center">
                <div class="avatar-info">
                  <h6 >5</h6>
                  <p style="font-size: 12px">Latest By {{ row.user.name}}</p>
                </div>
              </div>
            </template>
            <template slot="created_by" slot-scope="createdAt,row">
              <div class="table-avatar-info" style="align-items: center">
                <div class="avatar-info">
                  <h6>{{ createdAt | moment("MMMM Do YYYY, hh:mm a") }}</h6>
                  <p>By {{ row.user.name}}</p>
                </div>
              </div>
            </template>

            <template slot="status" slot-scope="status">
              <a-tag class="tag-status" :class="status ? 'ant-tag-danger' : 'ant-tag-success'">
                {{ status ? "Pending" : "Accepted" }}
              </a-tag>
            </template>

<!--            <template slot="action" slot-scope="row">-->
<!--              <a-button type="link" :data-id="row._id" @click="showEditForm(row)" class="btn-view icon-btn">-->
<!--                <a-icon type="form" theme="outlined"/>-->
<!--              </a-button>-->
<!--              <a-button type="link" :data-id="row._id" @click="showDelete(row._id)" class="btn-delete icon-btn">-->
<!--                <a-icon type="delete" theme="outlined"/>-->
<!--              </a-button>-->
<!--            </template>-->

          </a-table>
        </a-card>


      </a-col>

    </a-row>
  </div>
</template>

<script>

import {mapGetters} from 'vuex'

export default {
  data() {
    return {
      url: process.env.VUE_APP_SERVER_URL,
      filteredInfo: null,
      sortedInfo: null,
      searchText: "",
      searchInput: null,
      selectedItem: {},
      showModal: false,
      editMode: false,
      data: [],
      pagination: {pageSize:10},
      loading: false,
      authorsHeaderBtns: 'all',
      queryData: params => {
        return this.$store.dispatch("FETCH_POST_REPORTS", {params:{params: params},post_id: this.$route.params.id});
      }
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      const columns = [
        {
          title: 'S.No.',
          dataIndex: 'sno',
          width: '8%',
          scopedSlots: {customRender: 'sno'},
          align: 'center'
        },
        {
          title: 'Report Title',
          dataIndex: 'title',
          width: '30%',
          ellipsis: true,
          scopedSlots: {
            customRender: 'name'
          },
        },
        {
          title: 'Status',
          dataIndex: 'status',
          filters: [
            {text: 'Pending', value: 'pending'},
            {text: 'Accepted', value: 'accepted'},
          ],
          width: '10%',
          scopedSlots: {customRender: 'status'},
          filteredValue: filteredInfo.status || null,

        },
        {
          title: 'Created On',
          dataIndex: 'createdAt',
          scopedSlots: {customRender: 'created_by'},
          width: '25%',
          sorter: (a, b) => a.createdAt.length - b.createdAt.length,
          sortOrder: sortedInfo.columnKey === 'createdAt' && sortedInfo.order,

        },
        // {
        //   title: 'Action',
        //   width: '10%',
        //   scopedSlots: {customRender: 'action'},
        // },

      ];
      return columns;

    }},
  mounted() {
    this.fetch();
    this.postInfo();
  },
  methods: {
    postInfo(){
      this.loading=true
      this.$store.dispatch("FETCH_POST",{id:this.$route.params.id})
          .then((res)=>{
          })
          .finally(()=>{
            this.loading=false
          })
    },
    handleTableChange(pagination, filters, sorter) {
      const pager = {...this.pagination};
      pager.current = pagination.current;
      this.pagination = pager;
      this.filteredInfo = filters;
      this.sortedInfo = sorter;
      this.fetch({
        results: pagination.pageSize,
        page: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        ...filters,
      });
    },
    fetch(params = {}) {
      this.loading = true;
      this.queryData({
        results: this.pagination.pageSize ? this.pagination.pageSize : 10,
        page: this.pagination.current ? this.pagination.current : 1,
        ...params,
      }).then(({data}) => {
        const pagination = {...this.pagination};
        // Read total count from server
        // pagination.total = data.totalCount;
        pagination.total = data.total;
        this.loading = false;
        this.data = data.results;
        this.pagination = pagination;
      });
    },
    // showDelete(id) {
    //   this.$confirm({
    //     title: 'Are you sure want to delete this report?',
    //     content: 'This process is irreversible. Are you sure want to continue?',
    //     okText: 'Yes',
    //     okType: 'danger',
    //     cancelText: 'No',
    //     onOk: () => {
    //       this.$store.dispatch("DELETE_COLLECTION", id)
    //           .then((res) => {
    //             this.$message.success(res.data.message);
    //             this.fetch()
    //           })
    //           .catch((err) => {
    //             this.$message.error(err.response.data.message);
    //           })
    //           .finally(() => {
    //           });
    //     },
    //   });
    // },
    showEditForm(row) {
      this.editMode = true
      this.selectedItem = row
      this.showModal = true;

    },
    formSuccess() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
      this.fetch()
    },
    cancelForm() {
      this.showModal = false;
      this.editMode = false
      this.selectedItem = {}
    },
    handleSearch(selectedKeys, confirm) {
      confirm();
      this.searchText = selectedKeys[0];
    },
    handleReset(clearFilters) {
      clearFilters();
      this.searchText = "";
    },
    resetTable() {
      // this.handleReset()
      this.searchText = "";
      this.filteredInfo = null;
      this.sortedInfo = null;
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      // Object.assign(this.pagination,{})
      this.fetch()
    },
  },
};
</script>
<style scoped>
.editable-row-operations a {
  margin-right: 8px;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}
</style>
